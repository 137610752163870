<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const emit = defineEmits(['update']);

const dashboard_terra_store = useDashboardTerraStore();

const {
  feature_types_options,
  terra_configuration,
  active_container_id,
  is_loading_feature_types,
} = storeToRefs(dashboard_terra_store);

const form_data = ref({
  data: [],
});

async function updateFeatureTypes(data) {
  form_data.value.data = data;

  emit('update', data);
}
</script>

<template>
  <div>
    <HawkLoader v-if="is_loading_feature_types" class="!m-0 !mb-4" />
    <Vueform
      v-else-if="feature_types_options.length && !is_loading_feature_types"
      :key="active_container_id + feature_types_options.length"
      v-model="form_data"
      size="sm"
      sync
      class="mb-4"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <HawkTreeSelect
        :options="{
          name: 'data',
          label: $t('Feature types'),
        }"
        :data="feature_types_options"
        children_key="featureTypes"
        :initial_state="terra_configuration.feature_types || []"
        label_key="name"
        value_key="uid"
        select_type="LEAF_PRIORITY"
        @updateForm="updateFeatureTypes($event)"
      />
    </Vueform>
    <div v-else-if="!is_loading_feature_types" class="text-sm font-medium mb-4">
      {{ $t('No feature types available') }}
    </div>
  </div>
</template>
