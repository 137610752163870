<script setup>
import { watch } from 'vue';

import { isEqual } from 'lodash-es';

import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const dashboard_terra_store = useDashboardTerraStore();
const $services = inject('$services');
const graph_data = ref(null);
const loading = ref(false);
const payload = ref(null);

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.chart_type) {
    case 'vertical_graph':
      type = chart_config.value?.chart_display_mode === 'Unstacked' ? 'mscolumn2d' : 'stackedcolumn2d';
      break;
    case 'horizontal_bar':
      type = chart_config.value?.chart_display_mode === 'Unstacked' ? 'msbar2d' : 'stackedbar2d';
      break;
    case 'donut':
      type = 'doughnut2d';
      break;
  }
  return type;
});

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: chart_config.value?.x_label || payload.value?.x?.label || '',
      yAxisName: chart_config.value?.y_label || payload.value?.y?.label || '',
      subCaption: chart_config.value?.description
      || props.data.data.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
    ...graph_data.value,
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_terra_store.parse_terra_form_to_server_format(props.data?.data);
  try {
    const { data } = await $services.terra_view_service.get_graph({ body: payload.value });
    graph_data.value = data;
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
  }
}
if (props.id === 'preview')
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" :group_key="chart_type !== 'doughnut2d' && props.data?.data?.feature_group" />
  </div>
</template>
