<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardTerraFields from '~/dashboard/components/terra-fields/dashboard-terra-fields.vue';
import DashboardTerraFeatureTypes from '~/dashboard/components/terra-feature-types/dashboard-terra-feature-types.vue';

const dashboard_terra_store = useDashboardTerraStore();
const dashboard_store = useDashboardStore();

const {
  terra_schema,
  terra_widget_type,
  active_container_id,
  terra_configuration,
  workflow_options,
  data_set,
  properties_options,
  selected_projects,
  container_options,
} = storeToRefs(dashboard_terra_store);

const {
  update_terra_configuration,
  set_containers,
  set_workflows,
  set_container_projects,
  set_container_feature_types,
  set_data,
  set_properties,
} = dashboard_terra_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$ = ref(null);

function updateTerraConfiguration(data, key = null) {
  if (!key) {
    update_terra_configuration({
      ...terra_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...terra_configuration.value,
    };

    copy[key] = data;
    update_terra_configuration(copy);
  }

  validateForm();
}

async function validateForm() {
  if (!form$.value)
    return;
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();
  let is_valid = !form$.value.hasErrors;

  const has_projects = terra_configuration.value?.projects?.length > 0;
  const has_workprogress_fields = terra_configuration.value?.workprogress_fields?.length > 0;
  const has_feature_types = terra_configuration.value?.feature_types?.length > 0;
  const has_custom_timerange = terra_configuration.value?.timerange_range?.[0] && terra_configuration.value?.timerange_range?.[1];

  if (
    ['workprogress_terra_chart', 'map-view', 'feature_type_counts', 'terra_pivot_table'].includes(terra_widget_type.value)
    && !has_projects
  )
    is_valid = false;

  if (
    terra_widget_type.value === 'workprogress_terra_chart'
    && !has_workprogress_fields
  )
    is_valid = false;

  if (
    terra_widget_type.value === 'feature_type_counts'
    && !has_feature_types
  )
    is_valid = false;

  if (
    terra_widget_type.value === 'feature_type_counts'
    && terra_configuration.value.feature_group === 'extraProperties'
    && !terra_configuration.value.feature_properties
  )
    is_valid = false;

  if (
    terra_widget_type.value === 'workflow_pivot_table'
    && terra_configuration.value.column?.key === 'date'
    && terra_configuration.value.timerange_type === 'custom'
    && !has_custom_timerange
  )
    is_valid = false;

  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...terra_configuration.value,
    });
}

async function resetConfigOnAssetChange() {
  await set_containers();

  if (form$.value.el$('container')) {
    await form$.value.el$('container').update(container_options?.value?.[0]?.value || null);
    form$.value.el$('feature_properties').update(null);
    form$.value.el$('workflow').update(null);
    updateTerraConfiguration([], 'projects');
    updateTerraConfiguration([], 'feature_types');
    setFieldsData();
  }
};

function setFieldsData() {
  if (['workflow_pivot_table', 'workprogress_terra_chart'].includes(terra_widget_type.value))
    set_workflows();
  if (['workprogress_terra_chart', 'map-view', 'feature_type_counts', 'terra_pivot_table'].includes(terra_widget_type.value))
    set_container_projects();
  if (['map-view', 'feature_type_counts'].includes(terra_widget_type.value))
    set_container_feature_types();
};

watch(widget_asset, (new_val, old_val) => {
  if (new_val !== old_val) {
    resetConfigOnAssetChange();
    updateTerraConfiguration(widget_asset.value, 'asset_id');
  }
});

watch(terra_widget_type, () => {
  setFieldsData();
});

watch(active_container_id, (new_val, old_val) => {
  if (data_set.value && old_val && ['workprogress_terra_chart', 'map-view', 'feature_type_counts', 'terra_pivot_table'].includes(terra_widget_type.value)) {
    updateTerraConfiguration([], 'projects');
    form$.value.el$('feature_properties').update(null);
    set_container_projects();
  }
  if (data_set.value && old_val && ['map-view', 'feature_type_counts'].includes(terra_widget_type.value)) {
    updateTerraConfiguration([], 'feature_types');
    set_container_feature_types();
  }
});

watch(workflow_options, () => {
  if (!terra_configuration.value?.workflow && workflow_options.value?.length)
    form$.value.el$('workflow').update(workflow_options.value?.[0]?.value);
});

watch(selected_projects, async (new_val, old_val) => {
  if (!['terra_pivot_table'].includes(terra_widget_type.value))
    await set_properties();
  if (old_val?.length && !isEqual(new_val, old_val))
    form$.value.el$('feature_properties').update(properties_options.value?.[0]?.value);
});

onMounted(async () => {
  await set_containers();
  set_data();
  validateForm();
});

onBeforeUnmount(() => {
  dashboard_terra_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :schema="terra_schema"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    class="mb-4"
    :should_validate_on_mount="false"
    :display-errors="false"
    @change="updateTerraConfiguration($event)"
  />
  <DashboardTerraProjects
    v-if="['workprogress_terra_chart', 'map-view', 'feature_type_counts', 'terra_pivot_table'].includes(terra_widget_type)"
    @update="updateTerraConfiguration($event, 'projects')"
  />
  <DashboardTerraFields
    v-if="terra_widget_type === 'workprogress_terra_chart'"
    class="mb-1"
    @update="updateTerraConfiguration($event, 'workprogress_fields')"
  />
  <DashboardTerraFeatureTypes
    v-if="['map-view', 'feature_type_counts'].includes(terra_widget_type)"
    @update="updateTerraConfiguration($event, 'feature_types')"
  />
</template>
