<script setup>
import { isEmpty } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import FormFieldTree from '~/forms/molecules/form-field-tree.vue';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  form: {
    type: Object,
    default() {
      return {};
    },
  },
  step_number: {
    type: Number,
    default: -1,
  },
  is_update_feature: {
    type: Boolean,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  submit_function: {
    type: Function,
    default: null,
  },
});

const auth_store = useAuthStore();
const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();

const $t = inject('$t');
const $services = inject('$services');

const columns = ref({
  lg: {
    container: 12,
    label: 3,
    wrapper: 12,
  },
});
const form_data = ref({});
const terra_containers = ref([]);
const terra_feature_types = ref([]);
const is_fetching_terra = ref(true);

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: `Update ${props.is_update_feature ? 'feature' : 'instance'}`,
        description: `Use form data to update associated ${
                props.is_update_feature ? 'feature' : 'instance'
              } details`,
        criteria: 'associated_feature',
        feature_extra_properties: {},
      };
});
const get_field_options = computed(() => {
  const value_items = form_data.value.extra_properties?.map(val => val.value);
  const default_fields = form_workflow_store.get_fields(props.step_number, []);
  const table_fields = form_workflow_store.get_fields(props.step_number, ['short_text', 'multi_text', 'dropdown', 'label'], ['tablev2']);

  const fields = [...default_fields, ...table_fields].map((field) => {
    const index = value_items.find(key => key === field.slug);
    if (index !== -1)
      value_items.splice(index, 1);
    return {
      value: field.slug,
      label: field.name,
    };
  });
  return fields;
});

const get_added_options = computed(() => {
  const value_items = form_data.value.extra_properties.map(val => val.value);
  form_workflow_store.get_fields(props.step_number, [], ['default', 'tablev2']).forEach((field) => {
    const index = value_items.findIndex(key => key === field.slug);
    if (index !== -1)
      value_items.splice(index, 1);
  });
  return value_items.map(val => ({ value: val, label: val }));
});

const template_detail = computed(() => form_template_detail_store.form_template_detail);
const is_asset_scoped_template = computed(() => !!template_detail.value?.target_element?.asset);

function addProperty() {
  form_data.value.extra_properties.push({ key: '', value: null });
}

function get_children(item) {
  return item.children;
}

function handleFormFieldSelect(selected_field_data) {
  form_data.value.form_field = selected_field_data.slug;
}
function handleFeatureTypeSelect(selected_feature_data) {
  form_data.value.feature_type = selected_feature_data.uid;
}

async function save() {
  const feature_extra_properties = form_data.value.extra_properties.reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});
  if (props.submit_function)
    await props.submit_function({
      name: form_data.value.name,
      description: form_data.value.description,
      feature_extra_properties,
      criteria: form_data.value.criteria,
      feature_type: form_data.value.feature_type,
      form_field: form_data.value.form_field,
      lookup_property: form_data.value.lookup_property,
      lookup_projects: form_data.value.lookup_projects,
    });
}

async function fetchTerraContainers() {
  const { data } = await $services.terra_view_service.getAll({
    query: {
      asset: template_detail.value?.target_element?.asset,
      detail: true,
    },
  });

  terra_containers.value = data.map(container => ({
    ...container,
    children: container.groups.map(group => ({
      ...group,
      children: group.projects,
    })),
  }));
}

async function fetchFeatureTypes() {
  const { data } = await $services.feature_type_groups.getAll({
    query: {
      pk: true,
      asset: template_detail.value?.target_element?.asset,
    },
  });

  terra_feature_types.value = data.map(item => ({
    ...item,
    children: item.featureTypes,
  }));
}

async function init() {
  if (auth_store.check_split('update_feature_block_config')) {
    is_asset_scoped_template.value && await fetchTerraContainers();
    await fetchFeatureTypes();
  }

  is_fetching_terra.value = false;
  form_data.value = default_data.value;
  form_data.value.extra_properties = Object.keys(form_data.value.feature_extra_properties).map(key => ({ key, value: form_data.value.feature_extra_properties[key] }));
}
init();
</script>

<template>
  <Vueform
    v-model="form_data"
    :sync="true" size="sm"
    :display-errors="false"
    :endpoint="save"
    :messages="{ required: $t('This field is required') }"
    @change="form_workflow_store.is_sidebar_form_dirty = true"
  >
    <HawkLoader v-if="is_fetching_terra" class="col-span-12" />
    <div v-else class="col-span-12">
      <div class="flex justify-between items-start mb-10">
        <div class="w-80">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Configure Update Feature') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Update associated feature properties using form fields.') }}
          </div>
        </div>
        <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
      <div :class="{ 'pointer-events-none': is_disabled }">
        <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" :columns="columns" />
        <TextareaElement name="description" :label="$t('Description')" class="mb-5" :readonly="is_disabled" :columns="columns" />
        <hr class="mb-5">
        <div v-if="auth_store.check_split('update_feature_block_config')" class="text-gray-700">
          <template v-if="is_asset_scoped_template">
            <div class="text-sm font-semibold mb-5">
              {{ $t('Update criteria') }}
            </div>
            <RadiogroupElement
              class="mb-5"
              name="criteria"
              :items="[
                {
                  label: $t('Associated feature'),
                  value: 'associated_feature',
                },
                {
                  label: $t('Find and update'),
                  value: 'find_and_update',
                },
              ]"
            />
            <FormFieldTree
              class="mb-5"
              :data="form_workflow_store.get_fields(step_number, ['short_text', 'multi_text'])"
              option_id="slug"
              :tree_config="{
                options: {
                  columns,
                  name: 'form_field',
                  label: $t('Field'),
                  description: $t('Choose a field whose values needs to be searched'),
                  rules: 'required',
                  placeholder: $t('Select form field'),
                  object: false,
                  search: true,
                  native: false,
                  canClear: false,
                  required: true,
                  canDeselect: false,
                  conditions: [['criteria', 'find_and_update']],
                },
                select_type: 'LEAF_PRIORITY',
                select_view: 'SELECT',
                option_text_length: 25,
                show_expanded_search: true,
              }"
              @onSelect="handleFormFieldSelect($event[0])"
            />
            <HawkTreeSelect
              :key="is_fetching_terra"
              class="mb-5"
              :options="{
                columns,
                name: 'lookup_projects',
                object: false,
                search: true,
                label: $t('Layers'),
                rules: 'required',
                description: $t('Select the layers to lookup values in'),
                conditions: [['criteria', 'find_and_update']],
              }"
              :initial_state="form_data.lookup_projects"
              children_key="children"
              :get_children_func="get_children"
              :data="terra_containers"
              :option_text_length="25"
              :show_expanded_search="true"
              select_type="LEAF_PRIORITY"
              @updateForm="form_data.lookup_projects = $event"
            />
            <TextElement
              class="mb-5"
              name="lookup_property"
              :label="$t('Property')"
              :conditions="[['criteria', 'find_and_update']]"
              :columns="columns"
              :description="$t('Select the property to lookup if the entered values represents other than name')"
            />
          </template>
          <!-- Feature type -->
          <HawkTreeselect
            :key="terra_feature_types.length"
            class="mb-5"
            :disable_branch_nodes="true"
            :multi_select="false"
            :options="{
              columns,
              name: 'feature_type',
              label: $t('Type'),
              description: $t('Select the target feature type'),
              object: false,
              search: true,
            }"
            children_key="children"
            data_type="object"
            label_key="name"
            value_key="uid"
            :get_children_func="get_children"
            :data="terra_feature_types"
            select_type="LEAF_PRIORITY"
            select_view="SELECT"
            :option_text_length="25"
            :show_expanded_search="true"
            @updateForm="($event) => !isEmpty($event) && handleFeatureTypeSelect($event[0])"
          />
        </div>

        <!-- Properties -->
        <div class="mb-5 text-gray-700">
          <div class="text-sm font-semibold">
            {{ $t('Properties') }}
          </div>
          <div class="text-sm w-80">
            {{ $t('Map feature properties with form fields') }}
          </div>
        </div>

        <div class="grid grid-cols-12 text-sm font-semibold text-gray-700 mb-2">
          <div class="col-span-5">
            {{ $t('Key') }}
          </div>
          <div class="col-span-5">
            {{ $t('Value') }}
          </div>
        </div>
        <ListElement
          name="extra_properties"
          :rules="auth_store.check_split('update_feature_block_config') ? [] : ['required']"
          :controls="{ add: false, remove: false, sort: false }"
        >
          <template #default="{ index }">
            <ObjectElement
              :name="index"
            >
              <div class="col-span-12">
                <div class="grid grid-cols-12 mb-3">
                  <div class="col-span-5 mr-4">
                    <TextElement
                      name="key"
                      rules="required"
                      :attrs="{ autofocus: true }"
                    />
                  </div>
                  <div class="col-span-5 mr-4">
                    <SelectElement
                      name="value"
                      :items="[...get_field_options, ...get_added_options]"
                      :limit="get_field_options.length"
                      :can-clear="false"
                      :native="false"
                      :create="true"
                      :append-new-option="false"
                      rules="required"
                    >
                      <template #single-label="{ value }">
                        <div class="flex items-center h-full absolute left-0 top-0 bg-transparent leading-snug pl-3">
                          <HawkText :content="value.label" :length="14" />
                        </div>
                      </template>
                    </SelectElement>
                  </div>
                  <div
                    v-if="!is_disabled"
                    class="cursor-pointer mt-1.5"
                    @click="form_data.extra_properties.splice(index, 1)"
                  >
                    <IconHawkTrashOne class="text-gray-600 w-4.5 h-4.5" />
                  </div>
                </div>
              </div>
            </ObjectElement>
          </template>
        </ListElement>
        <HawkButton class="mt-2 mb-5" type="link" @click="addProperty">
          <IconHawkPlus
            class="text-primary-700 w-5 h-4.75"
          />
          <span>
            {{ $t('Add Property') }}
          </span>
        </HawkButton>
      </div>
      <div class="text-sm text-gray-700 mb-5">
        <span class="text-sm font-semibold">{{ $t('Note') }} :</span> {{ $t('This will only execute for the forms that are created for a feature.') }}
      </div>
      <hr class="mb-5">
      <div v-if="!is_disabled" class="flex justify-end">
        <ButtonElement
          :button-label="$t('Cancel')"
          class="mr-3"
          :secondary="true"
          @click="$emit('close')"
        />
        <ButtonElement
          :button-label="$t('Save')"
          :submits="true"
        />
      </div>
    </div>
  </Vueform>
</template>
