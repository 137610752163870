<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  step_name: {
    type: String,
    default: '',
  },
  step_index: {
    type: Number,
    default: 0,
  },
  is_preview_mode: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const form_detail_store = inject('form_detail_store');
const step = ref(null);

function get_step_visibility() {
  if (props.is_preview_mode && !route.query.submission_history)
    return true;
  const completed_steps = form_detail_store?.form_detail?.completed_steps?.map(
    step => +step.index,
  );
  const allowed_steps = [
    ...(completed_steps || []),
    ...(route.query.submission_history
      ? []
      : [+form_detail_store?.form_detail?.status?.index || 1]),
  ];
  // Irrespective of whether the form is submitted or not, we should only display
  // the steps that are either completed or the ones in the current step. The deleted steps i.e
  // step.active=false is only checked while previewing the form but not in the submission view since
  // we should not consider whether a step is deleted or not for the completed ones and the current step
  // will never be set to a deleted step.
  return allowed_steps.includes(props.step_index);
}

onMounted(() => {
  if (+form_detail_store?.form_detail?.status?.index === props.step_index)
    step.value?.scrollIntoView(true);
});

const is_clicked = ref(true);
const step_submission = form_detail_store?.form_submissions?.step_submissions?.[props.step_index];
</script>

<template>
  <div v-if="get_step_visibility()" ref="step">
    <div class="mb-4 bg-[#F2F4F7] p-3 rounded-md cursor-pointer" @click="step_submission ? is_clicked = !is_clicked : null">
      <div class="flex justify-between">
        <h1 class="text-lg font-semibold">
          {{ props.step_name }}
        </h1>
        <div v-if="step_submission">
          <icon-hawk-minus-circle v-if="is_clicked" />
          <icon-hawk-plus-circle v-else />
        </div>
      </div>
      <div v-if="step_submission" class="flex items-center">
        <div class="content-center text-[#667085] mr-2 text-sm">
          {{ $t('Submitted by') }}:
        </div> <div><HawkMembers class="bg-gray-200 mr-1 !flex" :members="step_submission?.submitted_by" type="badge" /> </div> <div class="text-sm font-normal">
          on {{ $date(step_submission?.submitted_at, "L_DATETIME_LONG") }}
        </div>
      </div>
    </div>
    <div v-if="is_clicked">
      <slot name="step_sections" :is_step_submitted="step_submission" />
    </div>
  </div>
</template>
